.profile-container {
    min-height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdf5f7;
    padding: 20px;
}

.profile-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.profile-box h2 {
    color: #c76a92;
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.75rem;
}

.profile-info {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 8px;
    background-color: #fdf5f7;
}

.profile-info p {
    margin: 10px 0;
    color: #5d3a45;
}

.profile-info strong {
    color: #c76a92;
    margin-right: 10px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #f4d1d8;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.form-group input:focus {
    outline: none;
    border-color: #c76a92;
}

.edit-button {
    width: 100%;
    padding: 12px;
    background-color: #c76a92;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.edit-button:hover {
    background-color: #b5517a;
}

.edit-button:disabled {
    background-color: #d4a0b7;
    cursor: not-allowed;
}

.edit-button.secondary {
    background-color: #f4d1d8;
    color: #c76a92;
}

.edit-button.secondary:hover {
    background-color: #efc1cc;
}

.logout-button {
    width: 100%;
    padding: 12px;
    background-color: #ffebee;
    color: #c62828;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

.logout-button:hover {
    background-color: #ffcdd2;
}

.success-message {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
}