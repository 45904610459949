/* Style for the navbar */
/* AI assistance for styling navbar */
.navbar {
    background-color: #f4d1d8;

    height: 70px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

}


.navbar-brand {
    color: #c76a92;

    font-weight: bold;
    font-size: 1.75rem;

}


.nav-link {
    color: #5d3a45;

    font-size: 1.1rem;

    margin-left: 30px;
    padding: 8px 15px;

    border-radius: 25px;

    transition: background-color 0.3s, color 0.3s;

}


.nav-link:hover {
    background-color: #ffb3c1;

    color: #fff;

}


.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

}


.search-container {
    flex-grow: 1;

    display: flex;
    justify-content: center;

}


.search-input {
    width: 300px;

    padding: 8px;

    border: 1px solid #ccc;

    border-radius: 5px;

    outline: none;

    transition: border 0.3s;

}


.search-input:focus {
    border: 1px solid #c76a92;

}


.search-button {
    background-color: #c76a92;

    color: white;

    border: none;

    padding: 8px 15px;

    border-radius: 5px;

    margin-left: 10px;

}


.cart-wishlist-container {
    display: flex;

    align-items: center;

}


.cart-count-badge,
.wishlist-count-badge {
    background-color: #c76a92;

    color: white;

    border-radius: 12px;

    padding: 3px 6px;

    margin-left: 5px;

}


.cart-wishlist-container a {
    color: #5d3a45;

    margin-left: 15px;

}


.cart-wishlist-container a:hover {
    color: #c76a92;

}