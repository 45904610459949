.login-container {
    min-height: calc(100vh - 70px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdf5f7;
    padding: 20px;
}

.login-box {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.login-box h2 {
    color: #c76a92;
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.75rem;
}

.form-group {
    margin-bottom: 20px;
}

.form-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #f4d1d8;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.form-group input:focus {
    outline: none;
    border-color: #c76a92;
}

.login-box button {
    width: 100%;
    padding: 12px;
    background-color: #c76a92;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-box button:hover {
    background-color: #b5517a;
}

.login-box button:disabled {
    background-color: #d4a0b7;
    cursor: not-allowed;
}

.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    text-align: center;
}

.register-link {
    text-align: center;
    margin-top: 20px;
    color: #5d3a45;
}

.register-link span {
    color: #c76a92;
    cursor: pointer;
    text-decoration: underline;
}

.register-link span:hover {
    color: #b5517a;
}