.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1600px;

    margin: 20px auto;

    padding: 20px;

    background-color: #f9f9f9;

}


.card {
    width: 25%;

    border-radius: 10px;

    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    margin-bottom: 20px;

    cursor: pointer;
    overflow: hidden;
    background-color: #ffffff;

}

.card:hover {
    transform: translateY(-5px);

    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);

}


.card-img-top {
    height: 200px;

    object-fit: cover;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.card-body {
    padding: 15px;

    text-align: center;

}


.card-title {
    font-size: 1.6rem;

    color: #5d3a45;

    font-weight: bold;
    margin-bottom: 10px;
}


.card-text {
    font-size: 1.2rem;

    color: #484343;

    margin-bottom: 10px;
}


.card-text strong {
    color: #726c6c;

}

/* Button styling */
.btn-primary {
    background-color: #c76a92;

    border: none;

    padding: 8px 15px;

    font-size: 0.9rem;

    transition: background-color 0.2s ease-in-out;

}

.btn-primary:hover {
    background-color: #e91e63;

}

/* Outline secondary button (wishlist) */
.btn-outline-secondary {
    color: #5d3a45;

    border: 1px solid #6c757d;

    padding: 8px 15px;

    font-size: 0.9rem;

    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    margin-left: 10px;
}

.btn-outline-secondary:hover {
    background-color: #7d8e9d;

    color: #fff;

}

/* Responsive styling for smaller screens */
@media (max-width: 1200px) {


    .card {
        width: 20%;

    }
}

@media (max-width: 768px) {


    .card {
        width: 45%;

    }
}

@media (max-width: 576px) {


    .card {
        width: 100%;

    }
}